<div class="select-wrapper">
  <button
    #select="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="select"
    (click)="openList()">
    <ng-content select="[title]"></ng-content>
    <svg-icon
      class="arrow"
      key="arrow"
      [class.rotate-arrow]="isOpen"></svg-icon>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="panel-offset"
  cdkConnectedOverlayBackdropClass="overlay-backdrop"
  [cdkConnectedOverlayOrigin]="select"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="backDropClick($event)">
  <ng-content select="[panel]"></ng-content>
</ng-template>
