import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, CdkOverlayOrigin, CdkConnectedOverlay],
})
export class SwitcherComponent {
  public isOpen = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public openList(): void {
    this.isOpen = true;
  }

  public closeList(): void {
    this.isOpen = false;
    this.cdr.markForCheck();
  }

  public backDropClick(event: MouseEvent): void {
    if (event.type === 'click') {
      this.closeList();
    }
  }
}
